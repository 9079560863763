import React, { useContext } from 'react';
import { QuoteContext } from '..';
import TrashCan from '../../assets/Trash-Can.png';
import { USCurrentStyle } from '../../utils';
import { DISCOUNTS } from '../../constant/discounts';
import NoShaveIcon from './NoShaveIcon';

function ProductRow({ option, optionKey, product }) {
  const { options, setOptions, clearOption } = useContext(QuoteContext);
  let DISCOUNTS_LIST = [];

  if (product.id.includes('bundle')) {
    DISCOUNTS_LIST = [
      DISCOUNTS.find((discount) => discount.label === 'Bundle'),
    ];
  } else {
    DISCOUNTS_LIST = [...DISCOUNTS].filter(
      (discount) => discount.label !== 'Bundle'
    );
  }

  const deleteProduct = (product, key) => {
    if (option.items.length === 1) {
      // if the last item is deleted
      clearOption(key);
    } else {
      setOptions((prev) => ({
        ...prev,
        [optionKey]: {
          ...options[optionKey],
          items: options[optionKey].items.filter(
            (elem) => elem.label !== product.label
          ),
        },
      }));
    }
  };

  const discountsDropdownHandler = (discount) => {
    const productIndex = option.items.findIndex(
      (elem) => elem.id === product.id
    );
    option.items[productIndex].discount = DISCOUNTS.find(
      (item) => item.label === discount
    );
    setOptions((prev) => ({ ...prev, [optionKey]: option }));
  };

  return (
    <div className='container py-2 border-bottom'>
      <div className='row'>
        <div className='col'>
          <p className='mb-0 athens-text-navy'>
            {product.productsGroupId === 'fue-standard'
              ? 'FUE'
              : product.productsGroupId === 'no-shave'
              ? 'NS'
              : null}{' '}
            {product.label}
            {product.isNoShaveAllowed && option.withNoShave && (
              <span className='d-inline-block ms-2 mb-0'>
                <NoShaveIcon width={18} />
              </span>
            )}
          </p>
        </div>
      </div>
      <div className='row justify-content-between align-items-center gx-1'>
        <div className='col-auto'>
          <p className='mb-0 athens-text-navy' style={{ minWidth: '65px' }}>
            {product.price > 0 ? USCurrentStyle(product.price) : 'Free'}{' '}
            {product.taxable && (
              <sup className='athens-text-primary fs-6 top-0'>*</sup>
            )}
          </p>
        </div>
        <div className='col-auto'>
          <select
            id='single_discount'
            name='single_discount'
            className='form-select py-1'
            style={{
              width: '160px',
              backgroundColor: 'inherit',
              fontSize: '14px',
            }}
            onChange={(e) => discountsDropdownHandler(e.target.value)}
            disabled={product.price <= 0}
            value={product.discount.label}
          >
            {DISCOUNTS_LIST.map((discount, d) => (
              <option key={d} value={discount.label}>
                {discount.label}
              </option>
            ))}
          </select>
        </div>
        <div className='col-auto print-hide'>
          <span
            className='pointer px-2'
            onClick={() => deleteProduct(product, optionKey)}
          >
            <img
              src={TrashCan}
              alt='trash can icon'
              style={{ maxWidth: '18px' }}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default ProductRow;
