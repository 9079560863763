import {
  calculateSum,
  calculateAPRMonthlyPayment,
  calcRequiredDownPmt,
} from '.';

const noShavePrice = 500; // This is the price to be added to eligible products

export const updateOptionProps = (option, active) => {
  const { financePlan, minRate, maxRate, requiredDownPaymentPercent } =
    active.lender;
  if (option.items.length === 0) return;

  // If no discount is applied // Default
  // NOTE: Some items are not fully taxable. Only part of it is (Like the MaxGraft and Laser Cap Bundle)
  const taxBeforeDiscountArr = option.items.map((item) =>
    item.taxable
      ? (item.taxable > 0 ? item.taxable : item.price) *
        (active.state.tax / 100)
      : 0
  );
  option.taxBeforeDiscount = calculateSum(taxBeforeDiscountArr);
  // Get the sum of all discounts values
  option.discount = calculateSum(
    option.items.map((item) => item.discount.value)
  );
  // If a discount is applied
  const taxAfterDiscountArr = option.items.map((item) =>
    // NOTE: if the item is partially taxable, Do NOT subtract the item discount value
    // taxable: it could be 'all' or a number
    item.taxable
      ? ((item.taxable > 0 ? item.taxable : item.price) -
          (item.taxable > 0 ? 0 : +item.discount.value)) *
        (active.state.tax / 100)
      : 0
  );
  option.taxAfterDiscount = calculateSum(taxAfterDiscountArr);
  // Get the total original price of all items
  option.totalOriginalCost = calculateSum(
    option.items.map(
      (item) =>
        item.price +
        (option.withNoShave && item.isNoShaveAllowed ? noShavePrice : 0)
    )
  );

  // Total Original Cost + Tax before discount (if any) // Important to calculate finalTotalCost
  const costPlusTaxBeforeDiscount =
    option.taxBeforeDiscount > 0
      ? option.totalOriginalCost + option.taxBeforeDiscount
      : option.totalOriginalCost;

  option.finalTotalCost = costPlusTaxBeforeDiscount - option.discount;

  // When a discount is applied
  if (option.taxAfterDiscount < option.taxBeforeDiscount) {
    // This means there's a discount applied
    // 1. Subtract the tax before discount from finalTotalCost
    option.finalTotalCost -= option.taxBeforeDiscount;
    // 2. Add the tax after discount to finalTotalCost
    option.finalTotalCost += option.taxAfterDiscount;
    // Otherwise, finalTotalCost will have both tax before and after discount added
  }

  // Set Down Payment to 0 if:
  // 1. if Down Payment checkbox is unchecked
  // 2. if totalFinanced is less than or equal to 0 // This is to prevent negative totalFinanced when removing items
  if (!option.downPmtEnabled || option.totalFinanced <= 0)
    option.downPayment = 0;

  // if Lender requires a down payment (Covered Care 36)
  if (requiredDownPaymentPercent > 0) {
    //   Initial Required Down Payment
    option.requiredDownPmt = option.finalTotalCost * requiredDownPaymentPercent;
    //  If down payment entered by user is greater than required down payment
    if (option.downPayment > option.requiredDownPmt) {
      const { additionalDownPmt, requiredDownPmt } = calcRequiredDownPmt(
        option.finalTotalCost,
        option.downPayment,
        requiredDownPaymentPercent
      );
      option.additionalDownPmt = additionalDownPmt;
      option.requiredDownPmt = requiredDownPmt;
      option.todaysPayment = additionalDownPmt + requiredDownPmt; // This should be equal to option.downPayment
    } else {
      // If down payment entered by user is less than required down payment
      // Ignore option.downPayment if it's less than requiredDownPmt
      option.additionalDownPmt = 0;
      option.todaysPayment = option.requiredDownPmt;
    }
    option.totalFinanced = option.finalTotalCost - option.todaysPayment;
  } else {
    // If Lender does not require a down payment
    // Reset requiredDownPmt and additionalDownPmt
    option.requiredDownPmt = 0;
    option.additionalDownPmt = 0;
    // If there's no required down payment
    option.todaysPayment = option.downPayment;
    option.totalFinanced = option.finalTotalCost - option.todaysPayment;
  }

  // NOTE: todaysPayment and downPayment might seem redundant, however:
  // .downPayment is the value entered by the user and sometimes it gets ignored or altered
  // .todaysPayment is the actual down payment to be made today

  option.financePlan = financePlan; // This is for Send EQuote

  option.monthlyPayment =
    minRate > 0 || maxRate > 0
      ? [
          calculateAPRMonthlyPayment(
            option.totalFinanced,
            minRate,
            financePlan
          ),
          calculateAPRMonthlyPayment(
            option.totalFinanced,
            maxRate,
            financePlan
          ),
        ]
      : [option.totalFinanced / financePlan];
};
