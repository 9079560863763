import React from 'react';
import NoShaveIcon from './NoShaveIcon';

const CustomCheckbox = ({ id, label, handleCheckBox, isChecked }) => {
  return (
    <div className={`checkbox-container`} onClick={() => handleCheckBox(id)}>
      <div className='checkbox-wrapper'>
        <div className={`checkbox ${isChecked ? 'checked' : ''} me-2`}>
          {isChecked && <span>✔</span>}
        </div>
        <div className='label'>{label}</div>
        {id === 'no-shave' && (
          <figure className='d-inline-block ms-2 mb-0'>
            <NoShaveIcon width={18} />
          </figure>
        )}
      </div>
    </div>
  );
};

export default CustomCheckbox;
