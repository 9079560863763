export const LENDERS = [
  {
    name: 'Select Lender',
    minRate: 0,
    maxRate: 0,
    financePlan: undefined,
    requiredDownPaymentPercent: 0,
    minLoanRequired: 0,
  },
  {
    name: 'Covered Care 36',
    minRate: 0.199, // 19.9%
    maxRate: 0.219, // 21.9%
    financePlan: 36,
    requiredDownPaymentPercent: 0.05, // 5% down payment required by lender
    minLoanRequired: 1500, // Minimum loan amount required by lender
  },
  {
    name: 'Synchrony 36',
    minRate: 0,
    maxRate: 0,
    financePlan: 36,
    requiredDownPaymentPercent: 0,
    minLoanRequired: 0,
  },
  {
    name: 'Synchrony 48',
    minRate: 0.0499, // 4.99%
    maxRate: 0.0999, // 9.99%
    financePlan: 48,
    requiredDownPaymentPercent: 0,
    minLoanRequired: 0,
  },
  {
    name: 'Synchrony 60',
    minRate: 0.0499, // 4.99%
    maxRate: 0.0999, // 9.99%
    financePlan: 60,
    requiredDownPaymentPercent: 0,
    minLoanRequired: 0,
  },
  {
    name: 'Athens 24',
    minRate: 0,
    maxRate: 0,
    financePlan: 24,
    requiredDownPaymentPercent: 0,
    minLoanRequired: 0,
  },
];
