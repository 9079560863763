import React from 'react';
import Card from 'react-bootstrap/Card';
import OptionTileCheckBoxes from './OptionTileCheckBoxes';
import DownPaymentInput from './DownPaymentInput';

function OptionTileInputs({ optionClone, optionKey, setOptions, active }) {
  return (
    <Card.Body className='p-0 text-center'>
      {optionClone.finalTotalCost > 0 && (
        <OptionTileCheckBoxes
          option={optionClone}
          {...{ setOptions, optionKey }}
        />
      )}
      {optionClone.downPmtEnabled && (
        <div className='container-fluid pt-1 athens-bg-light-blue'>
          {optionClone.downPmtEnabled && (
            <DownPaymentInput
              option={optionClone}
              {...{ active, setOptions }}
            />
          )}
        </div>
      )}
    </Card.Body>
  );
}

export default OptionTileInputs;
