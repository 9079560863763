export const initialOptionValues = {
  monthlyPayment: [],
  downPmtEnabled: false, // Down Payment Check Box
  downPayment: 0, // The amount of down payment entered manually
  requiredDownPmt: 0, // The amount of down payment required by lender
  additionalDownPmt: 0, // Down Payment entered manually - Required Down Payment
  todaysPayment: 0, // This could be down payment entered manually OR required amount by lender OR Required Down + Additional Down
  totalOriginalCost: 0, // Total Original Price of all items
  finalTotalCost: 0, // Your Price
  totalSavings: 0,
  totalFinanced: 0,
  discount: 0,
  items: [],
  taxBeforeDiscount: 0,
  taxAfterDiscount: 0,
  withNoShave: false, // No Shave Check Box
};

// NOTE: todaysPayment and downPayment might seem redundant, however:
// .downPayment is the value entered by the user and sometimes it gets ignored or altered
// .todaysPayment is the actual down payment to be made today
