import React from 'react';
import Card from 'react-bootstrap/Card';
import ProductRow from './ProductRow';

function OptionItemsWrapper({ optionClone, optionKey }) {
  return (
    <Card.Body id='option-items' className='p-0 rounded-bottom'>
      {optionClone.items.length > 0 &&
        optionClone.items.map((product, x) => (
          <ProductRow
            key={x}
            option={optionClone}
            {...{ optionKey, product }}
          />
        ))}
    </Card.Body>
  );
}

export default OptionItemsWrapper;
