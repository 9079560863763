import React, { useContext, useEffect } from 'react';
import { QuoteContext } from '.';
import { LENDERS } from '../constant/lenders';
import { STATES } from '../constant/states';

export function SelectionsSection() {
  const { active, setActive } = useContext(QuoteContext);

  // Unified handler for dropdown changes
  const onChangeHandler = (value, fieldName) => {
    switch (fieldName) {
      case 'state':
        if (value === STATES[0].name) return;
        setActive((prev) => ({
          ...prev,
          state: STATES.find((state) => state.name === value),
        }));
        localStorage.setItem('AthensStateName', value);
        break;

      case 'lender':
        if (value === LENDERS[0].name) return;
        setActive((prev) => ({
          ...prev,
          lender: LENDERS.find((lender) => lender.name === value),
        }));
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('AthensStateName');
    if (storedValue) {
      setActive((prev) => ({
        ...prev,
        state: STATES.find((state) => state.name === storedValue),
      }));
    }
  }, []);

  return (
    <section
      id='selections-section'
      className='container-fluid py-4 pt-sm-5 print-hide'
    >
      <div className='row justify-content-center gy-4 padding-x'>
        {/* State Selection */}
        <div className='col-auto'>
          <h3
            className={`h5 pb-1 ${
              active.state.name === STATES[0].name
                ? 'athens-text-primary'
                : 'athens-text-navy'
            }`}
          >
            State {active.state.name === STATES[0].name && <sup>*</sup>}
          </h3>
          <div>
            <select
              id='state'
              name='state'
              className={`form-select ${
                active.state.name === STATES[0].name ? 'warning-border' : ''
              }`}
              value={active.state.name}
              onChange={(e) => onChangeHandler(e.target.value, 'state')}
            >
              {STATES.map((state) => (
                <option key={state.name} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Lender Selection */}
        <div className='col-auto'>
          <h3
            className={`h5 pb-1 ${
              active.lender.name === LENDERS[0].name
                ? 'athens-text-primary'
                : 'athens-text-navy'
            }`}
          >
            Lender {active.lender.name === LENDERS[0].name && <sup>*</sup>}
          </h3>
          <div>
            <select
              id='lender'
              name='lender'
              className={`form-select ${
                active.lender.name === LENDERS[0].name ? 'warning-border' : ''
              }`}
              value={active.lender.name}
              onChange={(e) => onChangeHandler(e.target.value, 'lender')}
            >
              {LENDERS.map((lender) => (
                <option key={lender.name} value={lender.name}>
                  {lender.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SelectionsSection;
