import React from 'react';
import Card from 'react-bootstrap/Card';
import { USCurrentStyle, USCurrentStyleDecimal } from '../../utils';
import NoShaveIcon from './NoShaveIcon';

function OptionOutputsBottom({ option, active }) {
  return (
    <Card.Body className='output text-center pointer py-3 rounded-bottom athens-bg-light-blue'>
      {option.items.length > 0 && (
        <>
          {option.totalOriginalCost > option.finalTotalCost && (
            <p className='athens-text-navy mb-2'>
              <span>Original Price: </span>
              <span className='text-decoration-line-through athens-text-navy'>
                {USCurrentStyle(option.totalOriginalCost)}
              </span>
            </p>
          )}
          <p className='athens-text-navy mb-2'>
            <span>Your Price: </span>
            <span className='athens-text-navy'>
              {USCurrentStyle(option.finalTotalCost)}
            </span>
          </p>

          {option.requiredDownPmt > 0 && (
            <p className='athens-text-navy mb-2'>
              <span>Required Down Payment:</span>{' '}
              <span>{USCurrentStyleDecimal(option.requiredDownPmt)}</span>
            </p>
          )}
          {option.additionalDownPmt > 0 && (
            <p className='athens-text-navy mb-2'>
              <span>Additional Down Payment:</span>{' '}
              <span>{USCurrentStyleDecimal(option.additionalDownPmt)}</span>
            </p>
          )}

          {option.requiredDownPmt === 0 &&
            option.additionalDownPmt === 0 &&
            option.downPayment > 0 && (
              <p className='athens-text-navy mb-2'>
                <span>Down Payment:</span>{' '}
                <span>{USCurrentStyle(option.downPayment)}</span>
              </p>
            )}
          {option.totalFinanced !== option.finalTotalCost && (
            <p
              className={`${
                option.totalFinanced < 0 ? 'text-danger' : 'athens-text-navy'
              }  mb-2`}
            >
              <span>Total Financed:</span>{' '}
              <span>{USCurrentStyleDecimal(option.totalFinanced)}</span>
            </p>
          )}
          {option.totalFinanced < active.lender.minLoanRequired && (
            <small className='text-danger mb-2'>
              <small>
                Total financed cannot be less than{' '}
                {USCurrentStyleDecimal(active.lender.minLoanRequired)}
              </small>
            </small>
          )}
          {option.discount > 0 && (
            <p className='athens-text-primary fw-bold mb-2 fs-5'>
              Total Savings: {USCurrentStyle(option.discount)}
            </p>
          )}
          {option.taxAfterDiscount > 0 && (
            <p className='athens-text-navy mb-0'>
              <small>
                <small>
                  <sup className='athens-text-primary fs-6 top-0'>*</sup>{' '}
                  {active.state.tax}% Sales Tax Applied at Check Out for
                  Products
                </small>
              </small>
              {/* <small className="athens-text-navy">{USCurrentStyleDecimal(option.taxAfterDiscount)}</small> */}
            </p>
          )}
          {option.withNoShave > 0 && (
            <p className='print-display mb-0'>
              <small>
                <NoShaveIcon width={18} />
                <small className='ms-2 athens-text-primary'>
                  No Shave Fee Applied
                </small>
              </small>
            </p>
          )}
        </>
      )}
    </Card.Body>
  );
}

export default OptionOutputsBottom;
