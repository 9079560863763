import React, { useEffect, useState, useRef } from 'react';
import { InputUSCurrent, USCurrentStyleDecimal } from '../../utils';

const USNumberFormat = (value) => {
  const numericValue = parseFloat(value.toString().replace(/,/g, '')) || 0;
  return numericValue !== 0 ? InputUSCurrent(numericValue) : '';
};

const DownPaymentInput = ({ option, active, setOptions }) => {
  const inputRef = useRef(null);
  const { optionKey } = active;
  const { todaysPayment } = option;

  const minLoanRequired = active.lender.minLoanRequired;

  const [value, setValue] = useState(
    todaysPayment > 0 ? InputUSCurrent(todaysPayment) : ''
  );
  const [error, setError] = useState(null);

  const handleInputChange = (value) => {
    if (error !== null) setError(null);
    // let amount = +(value.toString().replace(/[^0-9]/g, '')) // Remove non-numeric characters
    let amount = +value.toString().replace(/[^\d.]/g, ''); // Remove non-numeric characters
    if (
      minLoanRequired > 0 &&
      amount > option.finalTotalCost - minLoanRequired
    ) {
      setError(
        `Total financed cannot be less than ${USCurrentStyleDecimal(
          minLoanRequired
        )}`
      );
      setTimeout(() => {
        setError(null);
      }, 5000);
      return;
    }

    // This if statement shouldn't be reachable. Keep it here just in case
    if (amount >= option.finalTotalCost) {
      setError('Down payment cannot exceed total cost');
      setTimeout(() => {
        setError(null);
      }, 5000);
      return;
    }

    if (amount < option.requiredDownPmt) {
      setError(
        `Down payment cannot be less than ${USCurrentStyleDecimal(
          option.requiredDownPmt
        )}`
      );
    }
    // Format the value as currency
    const formattedValue = USNumberFormat(amount);

    setValue(formattedValue);
    setOptions((prev) => ({
      ...prev,
      [optionKey]: {
        ...option,
        downPayment: amount,
      },
    }));
  };

  useEffect(() => {
    // setValue(USNumberFormat(todaysPayment));
    handleInputChange(todaysPayment);
  }, [todaysPayment]);

  useEffect(() => {
    // Focus the input element when the component mounts
    inputRef.current.focus();
  }, []);

  return (
    <div className='row gy-2 pt-2 pb-3 align-items-center print-hide'>
      <div className='col-xxl-5'>
        <p className='mb-0 lh-1'>
          <small>Down Payment</small>
        </p>
      </div>
      <div className='col-xxl-7'>
        <input
          ref={inputRef}
          type='text'
          value={value}
          onChange={(e) => handleInputChange(e.target.value)}
          className={`form-control currency-input text-center mx-auto ${
            error ? 'border-danger text-danger' : ''
          }`}
          placeholder='$0,000'
        />
      </div>
      {error && (
        <p className='text-danger lh-1 mt-1 mb-0'>
          <small>
            <small>{error}</small>{' '}
          </small>
        </p>
      )}
    </div>
  );
};

export default DownPaymentInput;
