import React from 'react';
import NO_SHAVE_ICON from '../../assets/no-shave-icon.png';

function NoShaveIcon({ width }) {
  return (
    <img
      src={NO_SHAVE_ICON}
      alt='No Shave Fee Icon'
      style={{ width: `${width}px` }}
    />
  );
}

export default NoShaveIcon;
