export const PRODUCTS = [
  {
    id: 'fue-standard',
    name: 'FUE Standard',
    products: [
      {
        id: 'fue-500-grafts',
        label: '500 Grafts',
        price: 7580,
        isNoShaveAllowed: true,
      },
      {
        id: 'fue-750-grafts',
        label: '750 Grafts',
        price: 10430,
        isNoShaveAllowed: true,
      },
      {
        id: 'fue-1000-grafts',
        label: '1000 Grafts',
        price: 13900,
        isNoShaveAllowed: true,
      },
      {
        id: 'fue-1250-grafts',
        label: '1250 Grafts',
        price: 15480,
        isNoShaveAllowed: true,
      },
      {
        id: 'fue-1500-grafts',
        label: '1500 Grafts',
        price: 16430,
        isNoShaveAllowed: true,
      },
      {
        id: 'fue-1750-grafts',
        label: '1750 Grafts',
        price: 17870,
        isNoShaveAllowed: false,
      },
      {
        id: 'fue-max-grafts',
        label: 'MaxGraft',
        price: 18150,
        isNoShaveAllowed: false,
      },
    ],
  },
  {
    id: 'other',
    name: 'Other',
    products: [
      {
        id: 'bundle-max-grafts-lllt',
        label: 'MAX RESULTS: MaxGraft and LLLT',
        price: 20150,
        isNoShaveAllowed: false,
      },
      {
        id: 'bundle-max-grafts-laser-cap',
        label: 'MAX RESULTS: MaxGraft and Laser Cap',
        price: 20150,
        isNoShaveAllowed: false,
        taxable: 2000, // Only the Laser Cap product is taxable
      },
      {
        id: 'lllt-max-results',
        label: 'LLLT Max Results',
        price: 3460,
        isNoShaveAllowed: false,
      },
      {
        id: 'laser-cap',
        label: 'Laser Cap',
        price: 2699,
        isNoShaveAllowed: false,
        taxable: 'all',
      },
      {
        id: 'genetic-testing',
        label: 'Genetic Testing',
        price: 0,
        isNoShaveAllowed: false,
      },
      {
        id: 'rx-compound',
        label: 'Rx Compound',
        price: 499,
        isNoShaveAllowed: false,
      },
    ],
  },
];
