import React from 'react';
import Card from 'react-bootstrap/Card';

function OptionTileStart({ active }) {
  return (
    <Card.Body className='py-2'>
      <h3 className='text-center athens-text-primary display-6'>$ _ _ _ _</h3>
      {active.lender.financePlan && (
        <p className='athens-text-light-navy text-center'>
          <small>
            Per month for <strong>{active.lender.financePlan} months</strong>
          </small>
        </p>
      )}
    </Card.Body>
  );
}

export default OptionTileStart;
