import React from 'react';

function ClearOptionBtn({ optionKey, clearOption, optionClone }) {
  return (
    <div className='text-center mt-4 print-hide'>
      <button
        type='button'
        className='athens-btn athens-primary-btn'
        onClick={() => clearOption(optionKey)}
        disabled={optionClone.items.length === 0}
      >
        Clear Option {optionKey}
      </button>
    </div>
  );
}

export default ClearOptionBtn;
