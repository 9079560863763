import React, { useContext, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { QuoteContext } from '..';
import { updateOptionProps } from '../../utils';
import OptionOutputsTop from './OptionOutputsTop';
import OptionOutputsBottom from './OptionOutputsBottom';
import OptionTileInputs from './OptionTileInputs';
import OptionTileStart from './OptionTileStart';
import OptionItemsWrapper from './OptionItemsWrapper';
import ClearOptionBtn from './ClearOptionBtn';

export function OptionWrapper({ option, optionKey }) {
  const { options, setOptions, active, setActive, clearOption } =
    useContext(QuoteContext);

  const optionClone = { ...option };
  if (optionClone.items.length > 0) updateOptionProps(optionClone, active);
  // This useEffect will update  Option props globally when:
  // Whenever the option final cost is affected. Which means, it will update for most actions
  // It will also update when lender or the downPayment is updated
  useEffect(() => {
    setOptions((prev) => ({ ...prev, [optionKey]: optionClone }));
  }, [optionClone.finalTotalCost, active.lender]);

  const isDisabled =
    optionKey > 1 &&
    optionClone.items.length === 0 &&
    options[optionKey - 1].items.length === 0;
  const onClickOption = (key) => {
    if (isDisabled) return;
    setActive((prev) => ({ ...prev, optionKey: +key }));
  };

  return (
    <div className='col-md-6 col-lg-4 col-print'>
      <div className='h-100'>
        <Card
          className={`option-card card-border ${isDisabled ? 'disabled' : ''} ${
            +optionKey === +active.optionKey ? 'active' : ''
          }`}
          onClick={() => onClickOption(optionKey)}
        >
          <div
            className={`output text-center pointer pt-4 rounded-top athens-bg-light-blue`}
          >
            <h2 className='athens-text-primary d-h text-uppercase mb-0'>
              Option {optionKey}
            </h2>
            {optionClone.items.length > 0 ? (
              <OptionOutputsTop option={optionClone} active={active} />
            ) : (
              <OptionTileStart active={active} />
            )}
          </div>
          <OptionItemsWrapper optionClone={optionClone} optionKey={optionKey} />

          <OptionOutputsBottom option={optionClone} active={active} />
          {optionClone.totalFinanced >= 0 && (
            <OptionTileInputs
              {...{ optionClone, optionKey, setOptions, active }}
            />
          )}
        </Card>
        <ClearOptionBtn {...{ optionKey, clearOption, optionClone }} />
      </div>
    </div>
  );
}

export default OptionWrapper;
