import React from 'react';
import CustomCheckbox from './CustomCheckbox';

function OptionTileCheckBoxes({ option, optionKey, setOptions }) {
  const showNoShaveCheckbox = option.items.some(
    (item) => item.isNoShaveAllowed
  );
  const handleCheckBox = (type) => {
    switch (type) {
      case 'down-payment':
        setOptions((prev) => ({
          ...prev,
          [optionKey]: {
            ...option,
            downPmtEnabled: !option.downPmtEnabled,
          },
        }));
        break;
      case 'no-shave':
        setOptions((prev) => ({
          ...prev,
          [optionKey]: {
            ...option,
            withNoShave: !option.withNoShave,
          },
        }));
        break;
      default:
        return;
    }
  };

  return (
    <div className='container-fluid athens-bg-white print-hide'>
      <div className='row gx-1'>
        <div className='col-6'>
          <div className='custom-checkbox py-1 text-start'>
            <CustomCheckbox
              id='down-payment'
              label='Down Payment'
              handleCheckBox={handleCheckBox}
              isChecked={option.downPmtEnabled}
            />
          </div>
        </div>
        {showNoShaveCheckbox && (
          <div className='col-6 custom-checkbox'>
            <div className='custom-checkbox py-1 pe-2 text-end'>
              <CustomCheckbox
                id='no-shave'
                label='No Shave'
                handleCheckBox={handleCheckBox}
                isChecked={option.withNoShave}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OptionTileCheckBoxes;
